import * as React from "react"

import { ContentfulTopic } from "../contentful/contentful-topic"
import {
  TopicSectionOptions,
  TopicSectionTheme,
  TopicSectionVariant,
} from "../contentful/contentful-topic-section"
import { TopicAction } from "./topic-action"
import { TopicContent } from "./topic-content"
import * as styles from "./topic.css"

export type TopicProps = {
  model: ContentfulTopic
  options: TopicSectionOptions
  theme: TopicSectionTheme
  variant: TopicSectionVariant
}

export const Topic = ({ model, options, theme, variant }: TopicProps) => {
  const { actions = undefined } = model

  const topicOptions = [
    options.reversed ? "reversed" : undefined,
    options.hideHeading ? "hide-heading" : undefined,
    options.hideAbstract ? "hide-abstract" : undefined,
    options.hideAction ? "hide-action" : undefined,
  ]

  let topicUrl = ``
  if (actions) {
    topicUrl = actions[0].url ? actions[0]?.url : actions[0].pageLink?.slug
  }

  let topicAsLink = false
  if (variant === "block" && actions) topicAsLink = true

  return (
    <TopicAction
      className={styles.topic({ theme, variant })}
      data-options={topicOptions.join(" ")}
      to={topicUrl}
      topicAsLink={topicAsLink}
    >
      <TopicContent
        model={model}
        options={options}
        theme={theme}
        topicAsLink={topicAsLink}
        variant={variant}
      />
    </TopicAction>
  )
}
