import * as React from "react"

import { ContentfulTextSection } from "../contentful/contentful-text-section"
import { ContentfulTopicSection } from "../contentful/contentful-topic-section"
import { TextSection } from "./text-section"
import { TopicSection } from "./topic-section"

type ContentfulSection = ContentfulTopicSection | ContentfulTextSection

export type SectionProps = {
  model: ContentfulSection[]
}

const Section = ({ model }: SectionProps) => {
  return (
    <>
      {model
        ? model.map((section: ContentfulSection, index: number) => {
            return (
              <React.Fragment key={index}>{getSection(section)}</React.Fragment>
            )
          })
        : null}
    </>
  )
}

export default Section

const getSection = (section: ContentfulSection) => {
  switch (section.__typename) {
    case `ContentfulTopicSection`:
      return <TopicSection model={section as ContentfulTopicSection} />

    case `ContentfulTextSection`:
      return <TextSection model={section as ContentfulTextSection} />

    default:
      return null
  }
}
