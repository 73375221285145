import * as React from "react"

import { ContentfulTopic } from "../contentful/contentful-topic"
import { ContentfulTopicSection } from "../contentful/contentful-topic-section"
import { Container } from "../elements/ui"
import { Topic } from "./topic"
import * as styles from "./topic-section.css"

export type TopicSectionProps = {
  model: ContentfulTopicSection
}

export const TopicSection = ({ model }: TopicSectionProps) => {
  const {
    topics,
    theme,
    reversed,
    hideHeading,
    hideAbstract,
    hideAction,
    variant,
  } = model

  const options = {
    reversed,
    hideHeading,
    hideAbstract,
    hideAction,
  }

  return (
    <section className={styles.section({ theme, variant })}>
      <Container className={styles.container({ variant })}>
        {topics.map((model: ContentfulTopic, index: number) => {
          const { id } = model

          return (
            <Topic
              key={id + index}
              model={model}
              options={options}
              variant={variant}
              theme={theme}
            />
          )
        })}
      </Container>
    </section>
  )
}
