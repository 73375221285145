import {
  Options,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"

import { vars } from "../../styles/globals.css"
import {
  ContentfulReference,
  ContentfulTextSection,
} from "../contentful/contentful-text-section"
import Link from "../elements/link"
import { Hr } from "../elements/ui"
import * as styles from "./text-section.css"

type OptionsProps = (references: ContentfulReference[]) => Options

const options: OptionsProps = references => {
  // Create map of Assets
  const contentfulAssetMap = new Map()
  for (const asset of references) {
    contentfulAssetMap.set(asset.contentful_id, asset)
  }

  return {
    renderMark: {
      [MARKS.BOLD]: text => <b>{text}</b>,
      [MARKS.ITALIC]: text => <i>{text}</i>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
      [MARKS.CODE]: text => <code>{text}</code>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <Link to={node.data.uri} style={{ color: vars.color.text }}>
          {children}
        </Link>
      ),
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
      [BLOCKS.HR]: () => <Hr style={{ margin: "auto" }} />,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (!contentfulAssetMap) return null
        const asset = contentfulAssetMap?.get(node.data.target.sys.id) || null
        console.log(asset)
        if (asset) {
          return <GatsbyImage image={asset.gatsbyImageData} alt={asset.title} />
        }
      },
    },
  }
}

type TextSectionProps = {
  model: ContentfulTextSection
}

export const TextSection = ({ model }: TextSectionProps) => {
  const {
    text: { raw },
  } = model

  return (
    <section className={styles.textSectionStyle}>
      <div className={styles.textContainerStyle}>
        {documentToReactComponents(
          JSON.parse(raw),
          options(model.text.references)
        )}
      </div>
    </section>
  )
}
