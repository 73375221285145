import { PageProps, graphql } from "gatsby"
import * as React from "react"

import { ContentfulPage } from "../components/contentful/contentful-page"
import Layout from "../components/layout"
import Section from "../components/models/section"
import Seo from "../components/seo"

type DataProps = {
  contentfulPage: ContentfulPage
}

const Page = ({ data }: PageProps<DataProps>) => {
  const model = data.contentfulPage?.sections
  const meta = data.contentfulPage

  return (
    <Layout>
      <Seo
        title={meta.seoTitle || null}
        description={meta.seoDescription || null}
        url={meta.slug || null}
      />
      <Section model={model} />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($pageId: String!) {
    contentfulPage(id: { eq: $pageId }) {
      ...page
    }
  }
`
