import * as React from "react"

import { GetTypesOf } from "../../lib/get-types-of"
import Link from "../elements/link"

type TopicActionProps = {
  to?: string
  topicAsLink: boolean
} & GetTypesOf["div"]

export const TopicAction = ({ topicAsLink, ...props }: TopicActionProps) => {
  if (!topicAsLink) {
    // Remove `to` property
    props.to = undefined
    return <div {...props} />
  }
  return <Link {...props} />
}
