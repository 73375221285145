import * as React from "react"

import { marked } from "marked"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Heading, Paragraph } from "../elements/ui"
import { TopicProps } from "./topic"
import { TopicAction } from "./topic-action"
import * as styles from "./topic.css"

type TopicContentProps = TopicProps & {
  topicAsLink: boolean
}

export const TopicContent = ({
  model,
  options,
  theme,
  topicAsLink,
  variant,
}: TopicContentProps) => {
  const { id, abstract, heading, actions, media } = model

  const image = getImage(media?.gatsbyImageData)
  const markdown = marked.parseInline(abstract?.abstract || "")
  
  return (
    <>
      <div className={styles.content[variant]}>
        <div className={styles.copy}>
          {options.hideHeading ||
            (heading && <Heading is="h2">{heading}</Heading>)}
          {options.hideAbstract ||
            (abstract && <Paragraph dangerouslySetInnerHTML={{ __html: markdown }} />)
          }
        </div>
        {options.hideAction ||
          actions?.map((action, index) => {
            return (
              <TopicAction
                key={index}
                className={styles.action({ theme, variant })}
                to={action.url ? `${action.url}` : `${action.pageLink?.slug}`}
                // If Topic is a Link return <div />
                topicAsLink={!topicAsLink}
              >
                {action.heading}
              </TopicAction>
            )
          })}
      </div>
      {image && (
        <GatsbyImage
          image={image}
          title={media.title}
          alt={media.description}
          className={styles.image[variant]}
        />
      )}
    </>
  )
}
